<template>
  <v-navigation-drawer
    :value="$store.state.settings.sidenavOpen"
    mobile-breakpoint="500"
    width="200"
    absolute
    clipped
    @input="toggleDrawer"
  >
    <v-fade-transition hide-on-leave>
      <div v-if="loading">
        <v-skeleton-loader class="mt-3" type="list-item" />
        <v-skeleton-loader v-for="num in [1, 2, 3, 4, 5, 6]" :key="num" type="list-item-two-line" />
      </div>

      <v-list v-else nav dense>
        <v-text-field
          v-model="searchQuery"
          :label="$t('search')"
          class="mt-0 px-2"
          single-line
          clearable
          prepend-inner-icon="mdi-magnify"
          @blur="$emit('blur', $event)"
          @focus="$emit('focus', $event)"
        />

        <v-list-item
          v-for="user in filteredUsers"
          :key="user.email"
          :class="{ 'v-list-item--active primary--text': user.email === selectedUser.email }"
          @click="$emit('select-user', user)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ user.full_name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-fade-transition>

    <template v-slot:append>
      <div class="d-flex align-center justify-center py-4 border-t">
        <span class="mr-2 subtitle-2"> {{ $t('developed_by') }}: </span>
        <a :href="$t('evolco_url')" class="d-flex" target="_blank">
          <img src="../assets/evolco-logo.png" alt="" height="20" />
        </a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { UPDATE_SETTING } from '../store/mutation-types';

export default {
  name: 'WorkerListDrawer',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: Array,
    selectedUser: Object,
  },

  data() {
    return {
      searchQuery: '',
    };
  },

  computed: {
    filteredUsers() {
      if (!this.searchQuery) {
        return this.users;
      }
      const q = this.searchQuery.toLowerCase();
      return this.users.filter(user => user.full_name?.toLowerCase().indexOf(q) > -1);
    },
  },

  methods: {
    toggleDrawer(val) {
      this.$store.commit(UPDATE_SETTING, {
        key: 'sidenavOpen',
        value: val,
      });
    },
  },
};
</script>
