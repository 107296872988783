<template>
  <div v-if="!$store.getters.currentUser" class="fill-height">
    <v-progress-linear
      v-if="isLoginPending"
      color="primary"
      style="position: absolute; top: 0"
      indeterminate
    />
    <WorkerListDrawer
      :loading="isDataLoading"
      :users="userArray"
      :selected-user="user"
      @blur="disableKeyDownHandler = false"
      @focus="disableKeyDownHandler = true"
      @select-user="selectUser"
    />
    <v-btn class="ma-3" icon @click="toggleDrawer(true)">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <div class="d-flex align-center flex-column">
      <h1 class="display-1 text-center">
        <template v-if="user.email">
          {{ user.full_name }}
        </template>
      </h1>
      <h1 class="grey--text text-center title">
        {{ $t(user.email ? 'enter_pin' : 'choose_worker') }}
      </h1>
      <div>
        <v-row v-if="user.email" style="max-width: 240px; margin: 0 auto">
          <v-col cols="12" class="text-center">
            <v-icon v-for="num in [1, 2, 3, 4]" :key="num" color="black" class="mx-2" small>
              {{ pin.length >= num ? 'mdi-circle' : 'mdi-circle-outline' }}
            </v-icon>
          </v-col>
          <v-col v-for="num in ['1', '2', '3', '4', '5', '6', '7', '8', '9']" :key="num" cols="4">
            <v-btn :disabled="pin.length > 3" fab depressed @click="addSymbol(num)">
              {{ num }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              :disabled="pin.length < 1 || isLoginPending"
              color="error"
              fab
              depressed
              @click="removeLastSymbol"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              :disabled="pin.length > 3 || isLoginPending"
              fab
              depressed
              @click="addSymbol('0')"
            >
              0
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import WorkerListDrawer from '../../components/WorkerListDrawer';
import crudMixin from '../../mixins/crud-mixin';
import userService from '../../api/user-service';
import { UPDATE_SETTING } from '../../store/mutation-types';
import eventBus, { OPEN_SNACKBAR } from '../../util/event-bus';
import authService from '../../api/auth-service';

export default {
  name: 'WorkerLogin',

  components: { WorkerListDrawer },

  mixins: [crudMixin],

  data() {
    return {
      isDataLoading: true,
      isLoginPending: false,
      userArray: [],
      user: {},
      pin: '',
      disableKeyDownHandler: false,
    };
  },

  created() {
    this.crudMixin_getAll(userService.getWorkers, userService.model);
    this.toggleDrawer(true);
  },

  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },

  methods: {
    onKeyDown(event) {
      if (this.disableKeyDownHandler || !this.user?.email) {
        return;
      }
      const pinKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      if (pinKeys.indexOf(event.key) > -1) {
        event.preventDefault();
        this.addSymbol(event.key);
      } else if (event.key === 'Backspace') {
        this.removeLastSymbol();
        event.preventDefault();
      }
    },

    addSymbol(symbol) {
      this.pin += symbol;
      if (this.pin.length === 4) {
        this.login();
      }
    },

    removeLastSymbol() {
      if (!this.pin?.length) {
        return;
      }
      this.pin = this.pin.slice(0, -1);
    },

    selectUser(user) {
      if (this.isLoginPending) {
        return;
      }
      if (user.email !== this.user.email) {
        this.pin = '';
      }
      this.user = user;
      if (this.$vuetify.breakpoint.xsOnly) {
        this.toggleDrawer(false);
      }
    },

    toggleDrawer(value) {
      this.$store.commit(UPDATE_SETTING, {
        key: 'sidenavOpen',
        value,
      });
    },

    async login() {
      this.isLoginPending = true;
      try {
        const loginRes = await authService.login(this.user.email, this.pin);
        await this.$store.dispatch('handleAuthData', loginRes.data);
        await this.$store.dispatch('getCurrentUser');
        this.isLoginPending = false;
        await this.$router.replace({ name: 'salary-report' });
      } catch (e) {
        if (e.response?.status === 401 || e.response?.status === 400) {
          eventBus.$emit(OPEN_SNACKBAR, this.$t('wrong_pin'));
        }
        this.isLoginPending = false;
        this.pin = '';
      }
    },
  },
};
</script>
